import { getDayOfYear } from "~/hooks/contentHooks";

export function buildUrl(
  base: string,
  route: string,
  params?: Record<string, any>
) {
  const urlParams = (() => {
    const urlParams = new URLSearchParams(params).toString();
    if (urlParams === "") {
      return "";
    }
    return `?${urlParams}`;
  })();

  return new URL(route + urlParams, base);
}

export function get<T>(url: URL): Promise<T> {
  const now = new Date();
  const minute = now.getMinutes();
  const dayOfYear = getDayOfYear(now);

  const uniqueCode = `${dayOfYear.toString().padStart(3, "0")}${minute
    .toString()
    .padStart(2, "0")}`;

  try {
    return fetch(`${url}&cache_time=${uniqueCode}`, {
      method: "GET",
      cache: "force-cache"
    }).then(x => x.json()) as Promise<T>;
  } catch {
    return new Promise(resolve => resolve({} as T));
  }
}

export function post<T>(url: string, params: any) {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  }).then(x => x.json()) as Promise<T>;
}

