import classNames from "classnames";
import { OTPInput as ReactOTPInput, type SlotProps } from "input-otp";

export default function OTPInput({
  value,
  onChange
}: {
  value: any;
  onChange: any;
}) {
  return (
    <ReactOTPInput
      maxLength={6}
      containerClassName="group flex items-center has-[:disabled]:opacity-30"
      render={({ slots }) => (
        <>
          <div className="flex">
            {slots.slice(0, 3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>

          <FakeDash />

          <div className="flex">
            {slots.slice(3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>
        </>
      )}
      value={value}
      onChange={onChange}
    />
  );
}

// Feel free to copy. Uses @shadcn/ui tailwind colors.
function Slot(props: SlotProps) {
  return (
    <div
      className={classNames(
        "relative w-9 h-11 text-md font-sans",
        "flex items-center justify-center",
        "transition-all duration-150",
        "border-pri dark:border-pri-d border-y border-r first:border-l first:rounded-l-md last:rounded-r-md",
        "group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20",
        "outline outline-1",
        { "outline-transparent": !props.isActive },
        { "outline-pri-d dark:outline-pri": props.isActive }
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-6 bg-pri-d dark:bg-pri" />
    </div>
  );
}

function FakeDash() {
  return (
    <div className="flex w-6 justify-center items-center">
      <div className="w-2.5 h-0.5 rounded-full bg-pri-d/10 dark:bg-pri/10" />
    </div>
  );
}

