import { buildUrl, get } from "~/utils/fetchers";
import type { AuthorPerm, HiveContent } from "~/utils/hive";

const SCOT_API = "https://scot-api.hive-engine.com/";
const TOKEN = "LEO";
const TAG = "leofinance";

export function fetchLatestPosts(): Promise<HiveContent[]> {
  return get(
    buildUrl(SCOT_API, "get_discussions_by_created", { token: TOKEN, tag: TAG })
  );
}
export function fetchTrendingPosts(limit?: number): Promise<HiveContent[]> {
  return get(
    buildUrl(SCOT_API, "get_discussions_by_trending", {
      token: TOKEN,
      tag: TAG,
      limit
    })
  );
}

export interface checkFollowing {
  follower: string;
  account: string;
  enabled?: boolean;
}

export function fetchFollowers({ follower, account }: checkFollowing) {
  return get(
    buildUrl(SCOT_API, "get_follower", {
      account,
      follower,
      limit: 500,
      hive: 1,
      status: "blog"
    })
  );
}

export function fetchFollowing({ follower, account }: checkFollowing) {
  return get(
    buildUrl(SCOT_API, "get_following", {
      account,
      follower,
      limit: 500,
      hive: 1,
      status: "blog"
    })
  );
}

interface ActiveScotVote {
  rshares: number;
  percent: number;
  voter: string;
}

export interface ScotContent {
  pending_token: number;
  total_payout_value: number;
  active_votes: ActiveScotVote[];
}

export type PossibleScotContent = ScotContent | null;

export function fetchScotContent(authorPerm: AuthorPerm): Promise<ScotContent> {
  if (authorPerm.author === undefined) return;
  return get(
    buildUrl(SCOT_API, `@${authorPerm.author}/${authorPerm.permlink}`, {
      TOKEN,
      hive: 1
    })
  ).then(({ [TOKEN]: content }: any) => content);
}
export function stripScotContent({
  pending_token,
  total_payout_value,
  active_votes
}: ScotContent): ScotContent {
  return { pending_token, total_payout_value, active_votes };
}

export interface ScotTokenInfo {
  precision: number;
}

export function fetchScotTokenInfo(token?: string): Promise<ScotTokenInfo> {
  return get(buildUrl(SCOT_API, "info", { token: token ? token : TOKEN }));
}
export function stripScotTokenInfo({
  precision
}: ScotTokenInfo): ScotTokenInfo {
  return { precision };
}
