import { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  unstable_usePrompt,
  useLocation
} from "@remix-run/react";
import Logo from "~/components/Logo";
import HIVE from "~/images/hive.svg";
import { Divider, VerticalMarquee } from "~/components/auth/Utilities";
import HiveCreateAccountModal from "~/components/auth/HiveCreateAccountModal";
import SocialSignup from "~/components/auth/SocialSignup";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "~/utils/firebase";
import { getAuth } from "firebase/auth";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export interface HiveAccountKeys {
  public: {
    posting: string;
    active: string;
    ownerKey: string;
    memo: string;
  };
  private: {
    ownerKey: string;
    activeKey: string;
    postingKey: string;
  };
  memo: string;
  password: string;
}

export function generateKeys(
  username: string,
  password: string
): HiveAccountKeys {
  let opts = {
    addressPrefix: "STM",
    chainId: "beeab0de00000000000000000000000000000000000000000000000000000000"
  };

  const dhive = window.dhive;

  const ownerKey = dhive.PrivateKey.fromLogin(username, password, "owner");
  const activeKey = dhive.PrivateKey.fromLogin(username, password, "active");
  const postingKey = dhive.PrivateKey.fromLogin(username, password, "posting");
  const memoKey = dhive.PrivateKey.fromLogin(username, password, "memo")
    .createPublic(opts.addressPrefix)
    .toString();

  const keys = {
    posting: postingKey.createPublic(opts.addressPrefix).toString(),
    active: activeKey.createPublic(opts.addressPrefix).toString(),
    ownerKey: ownerKey.createPublic(opts.addressPrefix).toString()
  };

  return {
    public: {
      ...keys,
      memo: memoKey
    },
    private: {
      ownerKey: ownerKey.toString(),
      activeKey: activeKey.toString(),
      postingKey: postingKey.toString()
    },
    memo: memoKey,
    password
  };
}

export const generateFileFromText = (generatedKeys: any, username: string) => {
  return `
    Welcome the the world of InLeo!\n
    Never share your private keys with anyone and never lose your private keys, there's no way to access them again. \n
    Keys of account ${username}.
    Active Private Key: ${generatedKeys.private.activeKey}
    Active Public Key: ${generatedKeys.public.active}\n
    Posting Private Key: ${generatedKeys.private.postingKey}
    Posting Public Key: ${generatedKeys.public.posting}\n
    Owner Private Key: ${generatedKeys.private.ownerKey}
    Owner Public Key: ${generatedKeys.public.ownerKey}\n
    Memo Key: ${generatedKeys.memo}\n
    Master Password: ${generatedKeys.password}
  `;
};

export default function Signup() {
  const location = useLocation();
  const [hiveCreateAccountModal, setHiveCreateAccountModal] = useState(false);

  
  useEffect(() => {
    window.onbeforeunload = () => ({});
    
    return () => {
      window.onbeforeunload = undefined;
    };
  }, [location]);
  
  if (location.pathname === "/signup/success") return <Outlet />;

  
  return (
    <div className="flex flex-col flex-1 w-full h-full pt-16 pb-8 overflow-y-auto">
      {/* logo */}
      <Link to="/" className="fixed top-4 left-8 w-12 h-12">
        <Logo
          className="text-pri dark:text-pri-d origin-left scale-[3.525] pointer-events-none"
          noText
        />
      </Link>

      {/* vertical marquee */}
      <VerticalMarquee />
      <div className="flex flex-1 flex-col justify-center items-center sm:items-start pl-0 sm:pl-28 lg:pl-40">
        <div className="flex flex-col w-fit h-full max-w-[540px] px-4 gap-y-6">
          {/* heading */}
          <div className="flex flex-col mb-5">
            <h1 className="text-[clamp(38px,4vw,62px)] font-black font-display uppercase leading-none whitespace-nowrap">
              join inleo
            </h1>
            <p className="text-[clamp(11.25px,1.185vw,18.375px)] font-black font-display uppercase text-pri/40 dark:text-pri-d/40 leading-none whitespace-nowrap">
              microblogging on the blockchain
            </p>
          </div>

          {/* social section */}
          <SocialSignup />

          <Divider />

          {/* hive section */}
          <button
            type="button"
            className="flex justify-center items-center w-fit mx-auto px-5 py-2 gap-x-3 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
            onClick={() => setHiveCreateAccountModal(true)}
          >
            <img src={HIVE} alt="Hive" className="flex shrink-0 w-7 h-7" />
            <span className="text-sm font-medium">Create hive account</span>
          </button>

          <div className="flex flex-col gap-y-2.5 mt-4">
            <strong className="font-semibold">Already have an account?</strong>
            <Link
              to="/login"
              role="button"
              title="Sign in"
              aria-label="Sign in"
              className="flex items-center justify-center w-fit py-2.5 px-8 rounded-full border border-acc text-acc text-sm font-medium hover:bg-acc/[.075] transition-colors duration-150"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>

      <HiveCreateAccountModal
        visibility={hiveCreateAccountModal}
        setVisibility={setHiveCreateAccountModal}
      />
    </div>
  );
}
