import { isSSR } from "./ssr";

export function getCookie(name: string) {
  if (typeof document === "undefined") return undefined;

  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]?.trim();
    if (!cookie) continue;
    if (cookie?.startsWith(name + "=")) {
      let cookieData;
      try {
        cookieData = cookie.substring(name.length + 1).split(".")[0];
        return JSON.parse(atob(decodeURIComponent(cookieData)));
      } catch (e) {
        if (!isSSR()) {
          if (!location) return { proxy: "", auth: {} };
          if (
            !location.pathname?.startsWith("/login") &&
            !location.pathname?.startsWith("/register")
          ) {
            location.replace("/login");
          }
          return { proxy: "", auth: {} };
        }
      }
    }
  }
}
