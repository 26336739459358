import { FloatingPortal } from "@floating-ui/react";
import {
  faAt,
  faCheck,
  faInfoCircle,
  faSpinner,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useSearchParams, useSubmit } from "@remix-run/react";
import classNames from "classnames";
import { LazyMotion, m } from "framer-motion";
import { useCallback, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import useAccountNameValid from "~/hooks/useAccountNameValid";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useGetRedirectTo } from "~/routes/login";
import { verifyGoogle } from "~/utils/firebase";
import { generateKeys } from "~/utils/hive";
import { loginInfraUser, signInfraUser } from "~/utils/infra";
import { generateRandomPassword } from "~/utils/single/generateRandomPassword";

const loadFeatures = () =>
  import("~/components/framermotion/features").then(res => res.default);

interface GoogleSignupModalProps {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GoogleSignupModal({
  visibility,
  setVisibility
}: GoogleSignupModalProps) {
  const modalInnerRef = useRef<HTMLDivElement>(null);

  const submit = useSubmit();
  const navigate = useNavigate();
  const redirectTo = useGetRedirectTo();
  const [searchParams] = useSearchParams();

  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const [isValid, isInvalid, isFetching] = useAccountNameValid(username);

  const referral = useMemo(
    () => searchParams.get("referral") || "leofinance",
    [searchParams]
  );

  const handleContinue = useCallback(() => {
    if (loading || isInvalid) return;

    setLoading(true);

    const randomPassword = generateRandomPassword(16);
    const generatedKeys = generateKeys(username, randomPassword);

    try {
      return verifyGoogle().then(async user => {
        const firebase_id_token = await user.user.getIdToken();

        signInfraUser({
          username: username.toLowerCase(),
          firebase_uid: user.user.uid,
          firebase_id_token: firebase_id_token,
          public: {
            posting_key: generatedKeys.public.posting,
            owner_key: generatedKeys.public.ownerKey,
            active_key: generatedKeys.public.active
          },
          private: {
            posting_key: generatedKeys.private.postingKey,
            owner_key: generatedKeys.private.ownerKey,
            active_key: generatedKeys.private.activeKey
          },
          memo_key: generatedKeys.memo,
          referral: referral ?? "",
          password: randomPassword
        })
          .then(_response => {
            const response = JSON.parse(JSON.stringify(_response));

            window.username = username;
            window.generatedKeys = generatedKeys;

            if (response[0]) {
              loginInfraUser({
                firebase_id_token: firebase_id_token
              })
                .then(_response => {
                  const response = JSON.parse(JSON.stringify(_response));

                  toast(
                    response[0]
                      ? "Synchronizing data with Hive may take up to 30 seconds"
                      : response[1],
                    {
                      type: response[0] ? "success" : "error",
                      theme: "dark",
                      draggable: false,
                      closeOnClick: false
                    }
                  );

                  if (response[0]) {
                    navigate(`/signup/success?redirectTo=${redirectTo}`);
                  }
                })
                .catch(() => setLoading(false));
            }
            toast(response[1], {
              type: response[0] ? "success" : "error",
              theme: "dark",
              draggable: false,
              closeOnClick: false
            });
          })
          .catch(() => setLoading(false));

        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  }, [username, loading, isInvalid, referral]);

  useOnClickOutside(modalInnerRef, () => setVisibility(false));

  return (
    <FloatingPortal>
      <LazyMotion features={loadFeatures}>
        {visibility && (
          <m.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            className="fixed inset-0 flex justify-center items-center bg-black/30 z-[10001]"
          >
            <div
              ref={modalInnerRef}
              className="flex flex-col min-w-[360px] sm:min-w-[520px] max-w-[540px] py-5 px-6 gap-y-6 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]"
            >
              <header className="flex flex-1 items-center justify-between gap-x-8">
                <div className="flex items-center gap-x-2">
                  <div className="w-7 h-7">
                    <img
                      src={require("~/images/google.svg")}
                      className="w-7 h-7"
                      alt=""
                    />
                  </div>
                  <h1 className="text-2xl font-bold">Sign up with Google</h1>
                </div>
                <button
                  type="button"
                  className="flex items-center justify-center w-8 h-8 rounded-full border border-pri/50 dark:border-pri-d/50 text-pri/80 dark:text-pri-d/80 hover:border-pri dark:hover-border-pri-d hover:text-pri dark:hover:text-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
                  onClick={() => setVisibility(false)}
                >
                  <FontAwesomeIcon icon={faX} size="xs" />
                </button>
              </header>

              <div className="flex items-start gap-x-3 max-w-[520px] py-3 px-4 bg-red-500/10 border border-red-500/50 rounded-xl">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  size="sm"
                  fixedWidth
                  className="text-red-500 mt-1"
                />
                <p className="text-sm text-red-500">
                  Once you verify your username with the selected verification
                  method, you <strong>can't</strong> change it or continue the
                  sign-up proccess with another username with same verification
                  method.
                </p>
              </div>

              <div className="flex flex-col gap-y-1">
                <label
                  htmlFor="username"
                  className="font-medium text-sm text-pri dark:text-pri-d pl-px"
                >
                  Username
                </label>

                <div className="relative flex flex-1 items-center">
                  <FontAwesomeIcon
                    icon={faAt}
                    size="sm"
                    className="absolute left-3.5 text-pri/60 dark:text-pri-d/60"
                  />

                  <input
                    id="username"
                    type="text"
                    className={classNames(
                      "w-full py-2.5 pr-3 pl-9 rounded-lg bg-pri dark:bg-pri-d border text-sm font-medium outline-2 outline-transparent outline-offset-1 focus:outline-pri-d dark:focus:outline-pri placeholder:text-pri/40 dark:placeholder:text-pri-d/40 transition-all duration-150",
                      {
                        "border-pri dark:border-pri-d": !isInvalid && !isValid,
                        "border-green-500 dark:border-green-500 focus:outline-green-500 dark:focus:outline-green-500":
                          isValid,
                        "border-red-500 dark:border-red-500 focus:outline-red-500 focus:dark:outline-red-500":
                          isInvalid
                      }
                    )}
                    placeholder="Enter your username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    maxLength={16}
                    autoFocus
                  />

                  {isFetching && (
                    <span className="absolute right-3">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="sm"
                        className="text-pri/70 dark:text-pri-d/70 animate-spin"
                      />
                    </span>
                  )}

                  {isValid && !isFetching && (
                    <span className="absolute right-3">
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="text-green-500 dark:text-green-500"
                      />
                    </span>
                  )}
                </div>

                {isInvalid && (
                  <small className="text-xs text-red-500">
                    This username is already taken or contains special
                    characters.
                  </small>
                )}
              </div>

              <button
                type="button"
                title="Sign up with Google"
                aria-label="Sign up with Google"
                className="flex justify-center items-center min-w-[138px] sm:min-w-[185px] h-[30px] sm:h-[40px] py-2.5 px-6 mx-auto rounded-full text-sm font-medium bg-pri-d dark:bg-pri text-pri-d dark:text-pri hover:opacity-90 transition-opacity duration-150 disabled:opacity-50 disabled:hover:opacity-50 disabled:cursor-not-allowed"
                onClick={() => handleContinue()}
                disabled={loading || isInvalid || username.length < 3}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                ) : (
                  "Sign up with Google"
                )}
              </button>
            </div>
          </m.div>
        )}
      </LazyMotion>
    </FloatingPortal>
  );
}

