import produce from "immer";
import { PostPreviewData } from "~/components/posts/PostPreviewData";
import { setter, type ImmerStateCreator } from "./";

export type State = {
  activePost: PostPreviewData | null;
  pushStartArray: () => void;
  setActivePost: (post: PostPreviewData) => void;
};

type Actions = {
  setActivePost: (post: PostPreviewData) => void;
  setPushStartArray: (pushStartArray: any) => void;
};

export type ThreadsStore = State & Actions;

export const createThreadsStore: ImmerStateCreator<ThreadsStore> = set => ({
  // state
  activePost: null,
  pushStartArray: () => null,

  // actions
  setActivePost: (post: PostPreviewData) =>
    setter<PostPreviewData>("threads", set, "activePost", post),
  setPushStartArray: (post: PostPreviewData) =>
    setter<PostPreviewData>("threads", set, "pushStartArray", post)
});
