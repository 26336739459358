import {
  faCheck,
  faInfoCircle,
  faKey,
  faLock,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useContext, useEffect, useState } from "react";
import { getCookie } from "~/utils/cookie";
import { generateFileFromText, generateKeys } from "./signup";
import { generateRandomPassword } from "~/utils/single/generateRandomPassword";
import classNames from "classnames";
import InfoHeader, {
  BackButton,
  InfoHeaderLabel
} from "~/components/InfoHeader";
import { FloatingPortal } from "@floating-ui/react";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import MasterKeys from "~/images/masterkeys.png";
import { LoaderFunction, redirect } from "@remix-run/node";
import { getActiveAccount } from "~/session.server";
import { useSubmit } from "@remix-run/react";
import { useAppStore } from "~/store";
import { ClientOnly } from "remix-utils/client-only";
import { Client } from "@hiveio/dhive";

export const hiveClientDefault = new Client(
  ["https://api.hive.blog", "https://api.deathwing.me"],
  {
    consoleOnFailover: false,
    timeout: 1,
    failoverThreshold: 1
  }
);

export const loader: LoaderFunction = async ({ request }) => {
  const activeAccount = await getActiveAccount(request);

  if (activeAccount === null) {
    return redirect("/threads");
  }

  return true;
};

export default function ClaimKeys() {
  const [activeAccount, isDarkMode] = useAppStore(store => [
    store.account.activeAccount,
    store.settings.dark
  ]);

  const [derivedKey, setDerivedKey] = useState<string | null>(null);
  const [masterPassword, setMasterPassword] = useState<string | undefined>(
    undefined
  );
  const [securityMessage, setSecurityMessage] = useState("");
  const [owner, setOwner] = useState<string>("");
  const [active, setActive] = useState<string>("");
  const [memo, setMemo] = useState<string>("");
  const [posting, setPosting] = useState<string>("");
  const [keysDownloaded, setKeysDownloaded] = useState();
  const [generatedKeys, setGeneratedKeys] = useState();
  const [submitted, setSubmitted] = useState(false);

  const generateRandom = () => {
    const randomPass = generateRandomPassword(32);
    setMasterPassword(randomPass);
  };

  const validatePassword = (password: string) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    return hasUppercase && hasLowercase && hasNumber;
  };

  useEffect(() => {
    const dhive = (window as any)?.dhive;

    if (!dhive) {
      toast("Something went wrong, please reload and try again.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3000
      });

      return;
    }

    setKeysDownloaded(false);

    if (masterPassword === undefined) return;

    if (masterPassword.length < 16) {
      setSecurityMessage("Password must be at least 16 characters long.");
    } else if (masterPassword.length > 32) {
      setSecurityMessage("Password length should not exceed 32 characters.");
    } else if (!validatePassword(masterPassword)) {
      setSecurityMessage(
        "Password must contain uppercase, lowercase letters, and numbers."
      );
    } else {
      setSecurityMessage("");
    }

    const privateActiveKey = dhive.PrivateKey.fromLogin(
      activeAccount?.name,
      masterPassword,
      "active"
    );
    const privatePostingKey = dhive.PrivateKey.fromLogin(
      activeAccount?.name,
      masterPassword,
      "posting"
    );
    const privateOwnerKey = dhive.PrivateKey.fromLogin(
      activeAccount?.name,
      masterPassword,
      "owner"
    );
    const memoKey = dhive.PrivateKey.fromLogin(
      activeAccount?.name,
      masterPassword,
      "memo"
    )
      .createPublic("STM")
      .toString();

    setOwner(privateOwnerKey.toString());
    setActive(privateActiveKey.toString());
    setPosting(privatePostingKey.toString());
    setMemo(memoKey.toString());
  }, [masterPassword]);

  useEffect(() => {
    const { auth, proxy } = getCookie("__session");

    //console.log(derivedKey);
    if (proxy === "leolock") {
      try {
        const parsedKeys = JSON.parse(auth);

        setDerivedKey(parsedKeys.owner_key);
      } catch {
        setDerivedKey(null);
      }
    }
  }, [setDerivedKey]);

  const [visibility, setVisibility] = useState(false);
  const submit = useSubmit();

  const handleSubmit = async () => {
    if (!activeAccount) return;

    if (validatePassword(masterPassword)) {
      const accountUpdateOp = {
        account: activeAccount?.name,
        owner: {
          weight_threshold: 1,
          account_auths: [],
          key_auths: [[generatedKeys.public.ownerKey.toString(), 1]]
        },
        active: {
          weight_threshold: 1,
          account_auths: [],
          key_auths: [[generatedKeys.public.active.toString(), 1]]
        },
        posting: {
          weight_threshold: 1,
          account_auths: [],
          key_auths: [[generatedKeys.public.posting.toString(), 1]]
        },
        memo_key: generatedKeys.memo,
        json_metadata: "",
        posting_json_metadata: ""
      };

      const dhive = (window as any)?.dhive;

      if (!dhive) {
        return toast("Something went wrong, please reload and try again.", {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3000
        });
      }

      // Sign the account update operation

      // Broadcast the account update operation to the Hive blockchain
      try {
        await hiveClientDefault.broadcast.updateAccount(
          accountUpdateOp,
          dhive.PrivateKey.fromString(derivedKey)
        );
        alert(
          `Account update broadcast successful for user: ${activeAccount.name} please logout and login with keys you downloaded. You will be redirected in 5 seconds.`
        );
        setSubmitted(true);
        setTimeout(() => {
          submit(
            {
              message: "Remove account session.",
              accountName: activeAccount.name
            },
            { method: "delete", action: "/login" }
          );
        }, 5100);
      } catch (error) {
        console.log({ error });
        alert(
          `Error broadcasting account update for user: ${activeAccount.name}`
        );
        return;
      }
    } else {
      alert("Password is not secure. Please follow the security requirements.");
    }
  };

  const handleDownloadKeys = useCallback(() => {
    if (!activeAccount) return;

    const generatedKeys = generateKeys(activeAccount.name, masterPassword);
    const element = document?.createElement("a");
    const file = new Blob(
      [generateFileFromText(generatedKeys, activeAccount.name)],
      {
        type: "text/plain"
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = activeAccount.name + "_hive_keys.txt";
    document?.body.appendChild(element);
    element.click();
    document?.body.removeChild(element);
    setGeneratedKeys(generatedKeys);
    setKeysDownloaded(true);
  }, [masterPassword, activeAccount]);

  return (
    <ClientOnly>
      {() => (
        <div className="w-full flex-1 pc:flex-none pc:w-8/12 border-0 tbl:border-x border-pri dark:border-pri-d shrink-0">
          <FloatingPortal>
            {visibility && (
              <FloatingHTMLOverlay
                onClick={ev => ev.stopPropagation()}
                className="duration-50 backdrop-blur-sm bg-overlay z-[1000] flex justify-center py-[10vh]"
                lockScroll
              >
                <div className="bg-pri dark:bg-pri-d rounded-lg flex flex-col w-4/12 h-fit p-8 border border-pri dark:border-pri-d">
                  <span className="mx-auto bg-red-500/10 text-red-500 mb-4 flex w-24 h-24 rounded-full justify-center items-center">
                    <FontAwesomeIcon icon={faWarning} size="3x" />
                  </span>

                  <span className="font-semibold text-lg text-center leading-snug mt-3">
                    I have securely downloaded my keys in a safe place and
                    acknowledge that nobody can recover my password if I lose my
                    keys.
                  </span>
                  <button
                    onClick={() => handleSubmit()}
                    className="rounded-xl border bg-green-500/10 text-green-500 border-green-500 p-4 text-sm font-semibold mt-10 hover:opacity-80 transition-opacity duration-150"
                  >
                    I have downloaded my keys and ready to update my keys.
                  </button>
                  <button
                    className="rounded-xl border bg-red-500/10 text-red-500 border-red-500 p-4 text-sm font-semibold mt-2 hover:opacity-80 transition-opacity duration-150"
                    onClick={() => {
                      setVisibility(false);
                      setTimeout(() => handleDownloadKeys(), 500);
                    }}
                  >
                    I am not ready.
                  </button>
                </div>
              </FloatingHTMLOverlay>
            )}
          </FloatingPortal>
          <InfoHeader className="!relative !border-b-0">
            <BackButton defaultRoute={undefined} />
            <InfoHeaderLabel>Claim Keys</InfoHeaderLabel>
          </InfoHeader>
          <div className="relative flex flex-1 flex-col">
            <div className="flex flex-1 flex-col text-pri dark:text-pri-d">
              <div className="flex flex-1 flex-col">
                <div className="flex flex-row flex-wrap items-center gap-x-2 px-8 gap-y-6 pb-6 md:pb-0 border-b border-pri dark:border-pri-d">
                  <div className="relative flex flex-1 justify-center items-center w-full min-w-[170px] max-w-[260px] overflow-hidden">
                    <img
                      className="block w-full h-full object-contain"
                      src={MasterKeys}
                      alt=""
                    />
                  </div>

                  <div className="flex flex-1 flex-col justify-center items-center min-w-[300px] text-center md:text-left md:justify-start md:items-start px-5 gap-y-1 [--from:_#181a1b] dark:[--from:_#fff] [--to:_rgb(120,86,255)] dark:[--to:_rgb(147,120,255)]">
                    <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r to-c-purple from-[var(--from)]">
                      Lite Account to Full Account
                    </h1>
                    <h2 className="text-base font-medium">
                      Take Full Self-Custody of Your Hive Blockchain Account
                    </h2>
                  </div>
                </div>

                <div className="flex flex-1 flex-col px-5 py-2">
                  <div className="w-full flex flex-col mt-5 mb-5">
                    <p className="font-light text-pri dark:text-pri-d leading-snug">
                      You can choose to either generate a randomized password (using the "Use Secure Password" button) or enter your own password.
                      If you
                      don't use the generator, the password entered should
                      include several special characters, symbols, numbers and
                      letters for the highest possible security.
                    </p>
                  </div>

                  <div className="flex items-center gap-x-4 py-3 px-4 rounded-lg bg-orange-500/[.10] text-orange-500">
                    <FontAwesomeIcon icon={faWarning} fixedWidth />

                    <p className="text-sm font-medium">
                      Once you've completed this claim process, the social login
                      option will no longer be available since you will be a
                      full self-custody account. This gives you a higher level
                      of blockchain wallet security but removes the easy access
                      point you've been using up until today.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-1 flex-col py-6">
                <ol className="relative flex flex-row items-center w-full text-sm font-medium text-center gap-3 mb-3 px-5">
                  <span className="absolute top-[17px] left-0 right-0 w-full h-[1px] border-t border-pri/50 dark:border-pri-d/50 z-0" />

                  <li
                    className={classNames(
                      "flex flex-1 sm:min-w-[155px] justify-center items-center transition-colors duration-150 z-10",
                      {
                        "border-pri dark:border-pri-d":
                          !validatePassword(masterPassword),
                        "border-acc": validatePassword(masterPassword)
                      }
                    )}
                  >
                    <span className="flex flex-col flex-1 items-center justify-center gap-y-2">
                      <span
                        className={classNames(
                          "rounded-full w-9 h-9 flex justify-center items-center text-sm font-bold transition-colors duration-150",
                          {
                            "bg-gray-200 dark:bg-zinc-700 text-pri dark:text-pri-d":
                              !validatePassword(masterPassword),
                            "bg-green-500 text-pri":
                              validatePassword(masterPassword)
                          }
                        )}
                      >
                        {validatePassword(masterPassword) ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="sm"
                            fixedWidth
                          />
                        ) : (
                          "1"
                        )}
                      </span>
                      <span
                        className={classNames(
                          "transition-colors duration-150",
                          {
                            "text-acc": validatePassword(masterPassword)
                          }
                        )}
                      >
                        Enter Password
                      </span>
                    </span>
                  </li>

                  <li
                    className={classNames(
                      "flex flex-1 sm:min-w-[155px] justify-center items-center transition-all duration-150 z-10",
                      {
                        "opacity-50": !validatePassword(masterPassword),
                        "border-pri dark:border-pri-d": !keysDownloaded,
                        "border-acc": keysDownloaded
                      }
                    )}
                  >
                    <span className="flex flex-1 flex-col items-center justify-center gap-y-2">
                      <span
                        className={classNames(
                          "rounded-full w-9 h-9 flex justify-center items-center text-sm font-bold transition-colors duration-150",
                          {
                            "bg-gray-200 dark:bg-zinc-700 text-pri dark:text-pri-d":
                              !keysDownloaded,
                            "bg-green-500 text-pri": keysDownloaded
                          }
                        )}
                      >
                        {keysDownloaded ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="sm"
                            fixedWidth
                          />
                        ) : (
                          "2"
                        )}
                      </span>
                      <span
                        className={classNames(
                          "transition-colors duration-150",
                          {
                            "text-acc": keysDownloaded
                          }
                        )}
                      >
                        Download Keys
                      </span>
                    </span>
                  </li>

                  <li
                    className={classNames(
                      "flex flex-1 sm:min-w-[155px] justify-center items-center transition-all duration-150 z-10",
                      {
                        "opacity-50": !keysDownloaded,
                        "border-pri dark:border-pri-d": !submitted,
                        "border-acc": submitted
                      }
                    )}
                  >
                    <span className="flex flex-1 flex-col items-center justify-center gap-y-2">
                      <span
                        className={classNames(
                          "rounded-full w-9 h-9 flex justify-center items-center text-sm font-bold transition-colors duration-150",
                          {
                            "bg-gray-200 dark:bg-zinc-700 text-pri dark:text-pri-d":
                              !submitted,
                            "bg-green-500 text-pri": submitted
                          }
                        )}
                      >
                        {submitted ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="sm"
                            fixedWidth
                          />
                        ) : (
                          "3"
                        )}
                      </span>

                      <span
                        className={classNames(
                          "transition-colors duration-150",
                          {
                            "text-acc": submitted
                          }
                        )}
                      >
                        Submit Keys
                      </span>
                    </span>
                  </li>
                </ol>

                <div className="px-5 my-6 pb-6 border-b border-pri dark:border-pri-d">
                  <label
                    htmlFor="input-group-1"
                    className="block mb-4 text-lg font-semibold text-gray-900 dark:text-white"
                  >
                    Your Master Password
                  </label>

                  <div className="relative mb-6 flex flex-col gap-4 shrink-0">
                    <div className="flex flex-1 justify-center items-center py-3 px-4 gap-x-5 border border-pri dark:border-pri-d rounded-lg">
                      <div
                        className={classNames(
                          "flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-full pointer-events-none bg-red-500 duration-150",
                          {
                            "bg-green-500 duration-150":
                              validatePassword(masterPassword)
                          }
                        )}
                      >
                        <FontAwesomeIcon icon={faKey} fixedWidth />
                      </div>
                      <input
                        type="text"
                        id="input-group-1"
                        onChange={e => setMasterPassword(e?.target?.value)}
                        minLength={16}
                        value={masterPassword}
                        className="flex flex-1 text-pri dark:text-pri-d font-medium text-sm rounded-lg !border-transparent !border-0 w-full h-full min-h-[40px] !outline-none !focus:ring-0 !focus:ring-offset-0 bg-transparent placeholder:text-pri/60 dark:placeholder:text-pri-d/60"
                        placeholder="Enter a password to claim your keys."
                      />

                      <button
                        type="button"
                        className={classNames(
                          "absolute right-3 flex justify-center items-center w-8 h-8 rounded-full hover:bg-pri-d/10 dark:hover:bg-pri/10 text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d transition-all duration-150",
                          {
                            "opacity-0 cursor-not-allowed": !masterPassword
                          }
                        )}
                        onClick={() => {
                          if (!navigator || !masterPassword) return;

                          try {
                            navigator.clipboard.writeText(masterPassword);
                            toast("Key successfully copied.", {
                              type: "success",
                              theme: isDarkMode ? "dark" : "light",
                              autoClose: 3_000
                            });
                          } catch {
                            toast("There is an error while copying key.", {
                              type: "error",
                              theme: isDarkMode ? "dark" : "light",
                              autoClose: 3_000
                            });
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy} size="sm" fixedWidth />
                        <span className="sr-only">Copy</span>
                      </button>
                    </div>

                    {securityMessage && (
                      <div className="flex items-center gap-3 py-4 px-5 rounded-md bg-red-500/10 text-red-500 hover:shadow-md duration-150">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span className="text-sm font-semibold">
                          {securityMessage}
                        </span>
                      </div>
                    )}

                    <div className="flex flex-row flex-wrap justify-between w-full gap-4">
                      <button
                        onClick={() => generateRandom()}
                        className="min-w-[150px] flex flex-1 gap-x-3 justify-center border items-center rounded-full py-3 px-3 text-sm font-semibold align-middle duration-150 border-green-500 bg-green-500/10 text-green-500 hover:opacity-80 transition-all"
                      >
                        <FontAwesomeIcon icon={faLock} />
                        {/* Generate Random */}
                        Use Secure Password
                        <span className="absolute -bottom-6 text-xs font-normal text-pri/60 dark:text-pri-d/60">
                          Recommended
                        </span>
                      </button>

                      <button
                        onClick={
                          keysDownloaded
                            ? () => setVisibility(true)
                            : handleDownloadKeys
                        }
                        disabled={!validatePassword(masterPassword)}
                        className={
                          "min-w-[150px] flex flex-1 justify-center items-center rounded-full py-3 px-3 text-sm font-semibold align-middle disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-150 bg-acc text-pri hover:opacity-80"
                        }
                      >
                        {keysDownloaded ? "Submit" : "Download Keys"}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 grid-rows-5 gap-4 px-5">
                  <div className="rounded-lg border border-pri dark:border-pri-d p-6 flex flex-row relative overflow-hidden">
                    <span className="absolute opacity-10 text-acc p-6 text-4xl font-extrabold w-full tracking-[8rem] indent-4 flex items-center justify-center duration-150 transition-transform">
                      {posting || "POSTING"}
                    </span>
                    <div className="flex flex-col justify-center items-center text-2xl font-bold border-r px-8 w-2/6 min-w-[208px] tracking-wide border-pri dark:border-pri-d z-0">
                      <span>POSTING</span>
                      <span>KEY</span>
                    </div>
                    <div className="pl-6 z-10 flex flex-col w-full">
                      <div className="flex flex-row text-lg font-semibold leading-snug mb-2">
                        The posting key is used for various activities on Hive,
                        including:
                      </div>
                      <ul className="mt-2 text-pri/70 dark:text-pri-d/70 font-normal">
                        <li>—&nbsp;&nbsp;&nbsp;Posting content</li>
                        <li>—&nbsp;&nbsp;&nbsp;Commenting on posts</li>
                        <li>—&nbsp;&nbsp;&nbsp;Upvoting/Downvoting</li>
                        <li>—&nbsp;&nbsp;&nbsp;Follower actions</li>
                        <li>—&nbsp;&nbsp;&nbsp;Claiming rewards</li>
                      </ul>
                    </div>
                  </div>

                  <div className="rounded-lg border border-pri dark:border-pri-d p-6 flex flex-row relative overflow-hidden">
                    <span className="absolute opacity-10 text-acc p-6 text-4xl font-extrabold w-full tracking-[8rem] indent-4 flex items-center justify-center">
                      {active || "ACTIVE"}
                    </span>
                    <div className="flex flex-col justify-center items-center text-2xl font-bold border-r px-8 w-2/6 min-w-[208px] tracking-wide border-pri dark:border-pri-d z-0">
                      <span>ACTIVE</span>
                      <span>KEY</span>
                    </div>
                    <div className="pl-6 z-10 flex flex-col w-full">
                      <div className="flex flex-row text-lg font-semibold leading-snug mb-2">
                        The active key is used for:
                      </div>
                      <ul className="mt-2 text-pri/70 dark:text-pri-d/70 font-normal">
                        <li>—&nbsp;&nbsp;&nbsp;Confirming transactions</li>
                        <li>—&nbsp;&nbsp;&nbsp;Managing trades</li>
                        <li>—&nbsp;&nbsp;&nbsp;Adjusting user settings</li>
                      </ul>
                    </div>
                  </div>

                  <div className="rounded-lg border border-pri dark:border-pri-d p-6 flex flex-row relative overflow-hidden">
                    <span className="absolute opacity-10 text-acc p-6 text-4xl font-extrabold w-full tracking-[8rem] indent-4 flex items-center justify-center">
                      {memo || "MEMO"}
                    </span>
                    <div className="flex flex-col justify-center items-center text-2xl font-bold border-r px-8 w-2/6 min-w-[208px] tracking-wide border-pri dark:border-pri-d z-0">
                      <span>MEMO</span>
                      <span>KEY</span>
                    </div>
                    <div className="pl-6 z-10 flex flex-col w-full">
                      <div className="flex flex-row text-lg font-semibold leading-snug mb-2">
                        The memo key is used for:
                      </div>
                      <p className="mt-2 text-pri/70 dark:text-pri-d/70 font-normal">
                        Reading and sending encrypted memos (messages) on Hive.
                      </p>
                    </div>
                  </div>

                  <div className="rounded-lg border border-pri dark:border-pri-d p-6 flex flex-row relative overflow-hidden">
                    <span className="absolute opacity-10 text-acc p-6 text-4xl font-extrabold w-full tracking-[8rem] indent-4 flex items-center justify-center">
                      {owner || "OWNER"}
                    </span>
                    <div className="flex flex-col justify-center items-center text-2xl font-bold border-r px-8 w-2/6 min-w-[208px] tracking-wide border-pri dark:border-pri-d z-0">
                      <span>OWNER</span>
                      <span>KEY</span>
                    </div>
                    <div className="pl-6 z-10 flex flex-col w-full">
                      <div className="flex flex-row text-lg font-semibold leading-snug mb-2">
                        The owner key is the most important key:
                      </div>
                      <ul className="mt-2 text-pri/70 dark:text-pri-d/70 font-normal">
                        <li>
                          —&nbsp;&nbsp;&nbsp;Required to change all other
                          account keys
                        </li>
                        <li>
                          —&nbsp;&nbsp;&nbsp;Loss of this key could lead to
                          account compromise
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="rounded-lg border border-pri dark:border-pri-d p-6 flex flex-row relative overflow-hidden">
                    <span className="absolute opacity-10 text-acc p-6 text-4xl font-extrabold w-full tracking-[8rem] indent-4 flex items-center justify-center">
                      {masterPassword || "MASTER PASSWORD"}
                    </span>
                    <div className="flex flex-col justify-center items-center text-2xl font-bold border-r px-8 w-2/6 min-w-[208px] tracking-wide border-pri dark:border-pri-d z-0">
                      <span>MASTER</span>
                      <span>PASSWORD</span>
                    </div>
                    <div className="pl-6 z-10 flex flex-col w-full">
                      <div className="flex flex-row text-lg font-semibold leading-snug mb-2">
                        The master password:
                      </div>
                      <p className="mt-2 text-pri/70 dark:text-pri-d/70 font-normal">
                        Is used to derive all of the above keys and should be
                        used sparingly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ClientOnly>
  );
}
