import { FloatingPortal } from "@floating-ui/react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyMotion, m } from "framer-motion";
import { useRef, useState } from "react";
import useOnClickOutside from "~/hooks/useClickOutside";
import HIVE from "~/images/hive.svg";
import ChooseUsername from "./hive/ChooseUsername";
import type { HiveAccountKeys } from "~/utils/hive";
import VerificationMethod from "./hive/PhoneVerification";

const loadFeatures = () =>
  import("~/components/framermotion/features").then(res => res.default);

interface HiveCreateAccountState {
  username: string;
  verification_method: "twitter" | "phone" | null;
  generated_keys: HiveAccountKeys | null;
  keys_downloaded: boolean;
  current_step: number;
}

export interface CreateAccountHiveStepProps {
  createAccountState: HiveCreateAccountState;
  setCreateAccountState: (
    key: keyof HiveCreateAccountState,
    value: any
  ) => void;
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HiveCreateAccountModal({
  visibility,
  setVisibility
}: {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const modalInnerRef = useRef<HTMLDivElement>(null);

  const [createAccountState, setCreateAccountState] =
    useState<HiveCreateAccountState>({
      username: "",
      verification_method: null,
      generated_keys: null,
      keys_downloaded: false,
      current_step: 1
    });

  const updateCreateAccountState = (
    key: keyof HiveCreateAccountState,
    value: any
  ) => {
    setCreateAccountState(current => ({
      ...current,
      [key]: value
    }));
  };

  const props: CreateAccountHiveStepProps = {
    createAccountState,
    setCreateAccountState: updateCreateAccountState,
    visibility,
    setVisibility
  };

  const hiveSteps: Record<number, React.ReactNode> = {
    1: <ChooseUsername {...props} />,
    2: <VerificationMethod {...props} />
  };

  useOnClickOutside(modalInnerRef, () => setVisibility(false));

  return (
    <FloatingPortal>
      <LazyMotion features={loadFeatures}>
        {visibility && (
          <m.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            className="fixed inset-0 flex justify-center items-center bg-black/30 z-[10001]"
          >
            <div
              ref={modalInnerRef}
              className="flex flex-col min-w-[360px] sm:min-w-[520px] max-w-[540px] py-5 px-6 gap-y-6 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]"
            >
              <header className="flex flex-1 items-center justify-between gap-x-8">
                <div className="flex items-center gap-x-2">
                  <img
                    src={HIVE}
                    alt="Hive"
                    className="flex shrink-0 w-7 h-7"
                  />
                  <h1 className="text-2xl font-bold">Create Hive Account</h1>
                </div>
                <button
                  type="button"
                  className="flex items-center justify-center w-8 h-8 rounded-full border border-pri/50 dark:border-pri-d/50 text-pri/80 dark:text-pri-d/80 hover:border-pri dark:hover-border-pri-d hover:text-pri dark:hover:text-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
                  onClick={() => setVisibility(false)}
                >
                  <FontAwesomeIcon icon={faX} size="xs" />
                </button>
              </header>

              {hiveSteps[createAccountState.current_step]}
            </div>
          </m.div>
        )}
      </LazyMotion>
    </FloatingPortal>
  );
}

