import SocialLink from "./SocialLink";
import { useEffect, useState } from "react";
import TwitterSignupModal from "./TwitterSignupModal";
import GoogleSignupModal from "./GoogleSignupModal";
import { unstable_usePrompt } from "@remix-run/react";

export default function SocialSignup() {
  const [twitterSignup, setTwitterSignup] = useState(false);
  const [googleSignup, setGoogleSignup] = useState(false);

  return (
    <div className="flex flex-1 flex-wrap items-center justify-center gap-x-8 gap-y-2 pl-3">
      <SocialLink
        method="twitter"
        type="signup"
        onClick={() => setTwitterSignup(true)}
      />
      <SocialLink
        method="google"
        type="signup"
        onClick={() => setGoogleSignup(true)}
      />

      <TwitterSignupModal
        visibility={twitterSignup}
        setVisibility={setTwitterSignup}
      />
      <GoogleSignupModal
        visibility={googleSignup}
        setVisibility={setGoogleSignup}
      />
    </div>
  );
}
